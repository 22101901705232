import React from "react";
import PrivateRoute from "components/PrivateRoute";
// Containers
import Participants from "containers/Vote/participants";
import UserLogged from "../../containers/userLogged";
const SP = () => {
  return (
    <UserLogged>
      <PrivateRoute component={Participants} />
    </UserLogged>
  );
};

export default SP;